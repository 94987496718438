<template>
  <div class="throw-measuring">
    <template v-if="geolocationSupported"> </template>
    <h2>Throw Measuring</h2>
    <p class="sub-text" v-if="userPosition">
      GPS accuracy: {{ accuracy.meters }} meters, {{ accuracy.quality }}
    </p>
    <p class="location-error" v-if="locationError">
      This tool uses the location services of your device, please enable these
      and allow Tjing to use them.
    </p>
    <StandardSolidButton
      v-if="startPosition == null && userPosition != null"
      title="Start Measuring"
      :fluid="false"
      :desktopFluidity="true"
      @click="startMeasure()"
    />
    <div v-if="startPosition">
      <div class="running-distance">
        <h1>{{ throwDistance }}</h1>
        <p class="in-meters">meters</p>
      </div>
      <div class="measure-buttons">
        <StandardBorderedButton
          title="Cancel"
          :fluid="false"
          :desktopFluidity="true"
          @click="resetMeasure()"
        />
        <StandardSolidButton
          title="Stop"
          :fluid="false"
          :desktopFluidity="true"
          @click="endMeasure()"
        />
      </div>
    </div>
    <div v-if="measures.length > 0" class="measurments">
      <h3>Measurements</h3>
      <ul>
        <li v-for="measure in measures">{{ measure }} meters</li>
      </ul>
      <p class="clear-measurements" @click="clearMeasures()">Clear</p>
    </div>
  </div>
</template>

<script>
import { getDistance } from "geolib";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";

export default {
  name: "ThrowMeassuring",
  data() {
    return {
      userPosition: null,
      startPosition: null,
      endPosition: null,
      measures: [],
      locationError: false,
    };
  },
  components: {
    StandardSolidButton,
    StandardBorderedButton,
  },
  computed: {
    geolocationSupported() {
      return "geolocation" in navigator;
    },
    accuracy() {
      if (this.userPosition == null) return null;

      const meters = Math.round(this.userPosition.accuracy);
      let quality = "Bad";

      if (meters < 3) {
        quality = "Great";
      } else if (meters < 5) {
        quality = "Good";
      } else if (meters < 10) {
        quality = "Decent";
      } else if (meters < 20) {
        quality = "Poor";
      }

      let accuracy = {
        meters: meters,
        quality: quality,
      };

      return accuracy;
    },
    throwDistance() {
      const startPosition = this.startPosition;
      const userPosition = this.userPosition;

      if (startPosition && userPosition) {
        return getDistance(
          {
            latitude: startPosition.latitude,
            longitude: startPosition.longitude,
          },
          { latitude: userPosition.latitude, longitude: userPosition.longitude }
        );
      }
      return null;
    },
  },
  methods: {
    clearMeasures() {
      this.measures = [];
    },
    resetMeasure() {
      this.startPosition = null;
    },
    endMeasure() {
      const distance = this.throwDistance;
      this.measures.push(distance);
      this.startPosition = null;
    },
    startMeasure() {
      this.startPosition = this.userPosition;
    },
  },
  async mounted() {
    const positionTimer = navigator.geolocation.watchPosition(
      (position) => {
        // Your success code here
        this.userPosition = position.coords;
      },
      (err) => {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Location services unavailable",
          type: "error",
        });

        this.locationError = true;
      },
      {
        enableHighAccuracy: true,
      }
    );
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";

.throw-measuring {
  padding: 32px 12px;
}

h2 {
  text-align: center;
}

.sub-text {
  text-align: center;
  margin-bottom: 64px;
}

.location-error {
  text-align: center;
  margin-top: 64px;
}

.running-distance {
  h1 {
    text-align: center;
    font-size: 64px;
  }
  p {
    text-align: center;
  }
}

.measure-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;

  button {
    width: 47%;
  }
}

.measurments {
  margin-top: 64px;
}

.clear-measurements {
  text-align: center;
  color: $dusk;
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
</style>
